import { GET_ALL_AGENTS } from '../actions/types'

const initialState = {
  agents: []
}

const agentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_AGENTS:
      return Object.assign({}, state, {
        agents: action.payload
      })
    default:
      return state
  }
}

export default agentReducer
