import { GET_PRIVACY_POLICY, GET_TERMS } from '../actions/types'

const initialState = {
  privacy: '',
  terms: ''
}

const cmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TERMS:
      return Object.assign({}, state, {
        terms: action.payload
      })
    case GET_PRIVACY_POLICY:
      return Object.assign({}, state, {
        privacy: action.payload
      })
    default:
      return state
  }
}

export default cmsReducer
