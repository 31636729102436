import { GET_ALL_REWARD_PARTNERS, GET_ALL_REWARDS, GET_ALL_CLAIMS } from '../actions/types'

const initialState = {
  rewards: [],
  claims: []
}

const partnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REWARD_PARTNERS:
    case GET_ALL_REWARDS:
      return Object.assign({}, state, {
        rewards: action.payload
      })
    case GET_ALL_CLAIMS:
      return Object.assign({}, state, {
        claims: action.payload
      })
    default:
      return state
  }
}

export default partnerReducer
