export const TOGGLE_LOADER = 'TOGGLE_LOADER'
export const SET_INTRO = 'SET_INTRO'

export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'

export const SUCCESS = 'success'
export const FAILURE = 'failure'

export const UPDATE_AUTH_TOKEN = 'UPDATE_AUTH_TOKEN'

export const GET_INSURANCE_PROVIDERS = 'GET_INSURANCE_PROVIDERS'
export const GET_INSURANCE_PROVIDERS_FAILURE =
  'GET_INSURANCE_PROVIDERS_FAILURE'

export const GET_INSURANCE_PLANS = 'GET_INSURANCE_PLANS'
export const GET_INSURANCE_PLANS_FAILURE = 'GET_INSURANCE_PLANS_FAILURE'

export const EDIT_INSURANCE_SUCCESS = 'EDIT_INSURANCE_SUCCESS'
export const EDIT_INSURANCE_FAILURE = 'EDIT_INSURANCE_FAILURE'

export const VALIDATE_OTP_SUCCESS = 'VALIDATE_OTP_SUCCESS'
export const VALIDATE_OTP_FAILURE = 'VALIDATE_OTP_FAILURE'

export const USER_PREFERENCE_UPDATE = 'USER_PREFERENCE_UPDATE'

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'

export const GET_ALL_CUSTOMERS = 'GET_ALL_CUSTOMERS'
export const GET_ALL_CUSTOMERS_FAILURE = 'GET_ALL_CUSTOMERS_FAILURE'

export const ADD_CUSTOMERS = 'ADD_CUSTOMERS'
export const ADD_CUSTOMERS_FAILURE = 'ADD_CUSTOMERS_FAILURE'

export const GET_ALL_AGENTS = 'GET_ALL_AGENTS'
export const GET_ALL_AGENTS_FAILURE = 'GET_ALL_AGENTS_FAILURE'

export const GET_ALL_REWARD_PARTNERS = 'GET_ALL_REWARD_PARTNERS'
export const GET_ALL_REWARD_PARTNERS_FAILURE =
  'GET_ALL_REWARD_PARTNERS_FAILURE'

export const ADD_POLICY = 'ADD_POLICY'
export const ADD_POLICY_FAILURE = 'ADD_POLICY_FAILURE'

export const GET_POLICY = 'GET_POLICY'
export const GET_POLICY_FAILURE = 'GET_POLICY_FAILURE'

export const CLEAR_FLAG = 'CLEAR_FLAG'

export const LOGOUT = 'LOGOUT'

export const GET_AGENT_ANALYTICS = 'GET_AGENT_ANALYTICS'
export const GET_AGENT_ANALYTICS_FAILURE = 'GET_AGENT_ANALYTICS_FAILURE'

export const GET_CUSTOMER_ANALYTICS = 'GET_CUSTOMER_ANALYTICS'
export const GET_CUSTOMER_ANALYTICS_FAILURE = 'GET_CUSTOMER_ANALYTICS_FAILURE'

export const GET_PRIVACY_POLICY = 'GET_PRIVACY_POLICY'
export const GET_PRIVACY_POLICY_FAILURE = 'GET_PRIVACY_POLICY_FAILURE'

export const GET_TERMS = 'GET_TERMS'
export const GET_TERMS_FAILURE = 'GET_TERMS_FAILURE'

export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'

export const UPDATE_LOCATION = 'UPDATE_LOCATION'
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATION_FAILURE'

export const GET_ALL_AGENT_LOCATION = 'GET_ALL_AGENT_LOCATION'
export const GET_ALL_AGENT_LOCATION_FAILURE = 'GET_ALL_AGENT_LOCATION_FAILURE'

export const GET_BRIEZI_COINS = 'GET_BRIEZI_COINS'
export const GET_BRIEZI_COINS_FAILURE = 'GET_BRIEZI_COINS_FAILURE'

export const GET_ALL_PARTNER_REWARDS = 'GET_ALL_PARTNER_REWARDS'
export const GET_ALL_PARTNER_REWARDS_FAILURE =
  'GET_ALL_PARTNER_REWARDS_FAILURE'

export const GET_ALL_REWARDS = 'GET_ALL_REWARDS'
export const GET_ALL_REWARDS_FAILURE = 'GET_ALL_REWARDS_FAILURE'

export const ADD_REWARDS = 'ADD_REWARDS'
export const ADD_REWARDS_FAILURE = 'ADD_REWARDS_FAILURE'

export const UPDATE_REWARDS = 'UPDATE_REWARDS'
export const UPDATE_REWARDS_FAILURE = 'UPDATE_REWARDS_FAILURE'

export const GET_ALL_CLAIMS = 'GET_ALL_CLAIMS'
export const GET_ALL_CLAIMS_FAILURE = 'GET_ALL_CLAIMS_FAILURE'

export const EDIT_POLICY = 'EDIT_POLICY'
export const EDIT_POLICY_FAILURE = 'EDIT_POLICY_FAILURE'

export const DELETE_POLICY_SUCCESS = 'DELETE_POLICY_SUCCESS'
export const DELETE_POLICY_FAILURE = 'DELETE_POLICY_FAILURE'

export const SEND_LOAN_OTP = 'SEND_LOAN_OTP'
export const VERIFY_LOAN_OTP = 'VERIFY_LOAN_OTP'

export const SEND_SURRENDER_OTP = 'SEND_SURRENDER_OTP'
export const VERIFY_SURRENDER_OTP = 'VERIFY_SURRENDER_OTP'
export const SURRENDER_FAILURE = 'SURRENDER_FAILURE'
export const ADD_SURRENDER = 'ADD_SURRENDER'

export const ADD_LOAN = 'ADD_LOAN'
export const GET_ALL_LOAN = 'ALL_LOAN'
export const LOAN_FAILURE = 'LOAN_FAILURE'

export const NOT_VERIFIED = 'NOT_VERIFIED'

export const FOOD_ANALYZER_SUCCESS = 'FOOD_ANALYZER_SUCCESS'
export const FOOD_ANALYZER_FAILURE = 'FOOD_ANALYZER_FAILURE'

export const FOOD_ANALYZER_IMAGE = 'FOOD_ANALYZER_IMAGE'

export const UPDATE_BIOMETRY_SUCCESS = 'UPDATE_BIOMETRY_SUCCESS'
export const UPDATE_BIOMETRY_FAILURE = 'UPDATE_BIOMETRY_FAILURE'

export const UPDATE_FCM_SUCCESS = 'UPDATE_FCM_SUCCESS'
export const UPDATE_FCM_FAILURE = 'UPDATE_FCM_FAILURE'

export const GET_META_SUCCESS = 'GET_META_SUCCESS'
export const GET_META_FAILURE = 'GET_META_FAILURE'

export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS'
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE'

export const ADD_NOTIFICATION_SUCCESS = 'ADD_NOTIFICATION_SUCCESS'
export const ADD_NOTIFICATION_FAILURE = 'ADD_NOTIFICATION_FAILURE'

export const V1_AUTH_SUCCESS = 'V1_AUTH_SUCCESS'
export const V1_AUTH_FAILURE = 'V1_AUTH_FAILURE'

export const OPTIN_SUCCESS = 'OPTIN_SUCCESS'
export const OPTOUT_SUCCESS = 'OPTOUT_SUCCESS'
export const OPTIN_FAILURE = 'OPTIN_FAILURE'
export const OPTOUT_FAILURE = 'OPTOUT_FAILURE'

export const UPLOAD_DP_SUCCESS = 'UPLOAD_DP_SUCCESS'
export const UPLOAD_DP_FAILURE = 'UPLOAD_DP_FAILURE'

export const UPDATE_REFFERED_BY = 'UPDATE_REFFERED_BY'
export const UPDATE_REFFERAL_CODE = 'UPDATE_REFFERAL_CODE'
export const UPDATE_REFFERAL_CODE_FAILURE = 'UPDATE_REFFERAL_CODE_FAILURE'

export const GENERATE_PREMIUM_ORDER_ID = 'GENERATE_PREMIUM_ORDER_ID'
export const GENERATE_PREMIUM_ORDER_ID_FAILURE =
  'GENERATE_PREMIUM_ORDER_ID_FAILURE'

export const PAYMENT_CAPTURE_SUCCESS = 'PAYMENT_CAPTURE_SUCCESS'
export const PAYMENT_CAPTURE_FAILURE = 'PAYMENT_CAPTURE_FAILURE'

export const UPDATE_PERSONAL_DETAIL_SUCCESS = 'UPDATE_PERSONAL_DETAIL_SUCCESS'
export const UPDATE_PERSONAL_DETAIL_FAILURE = 'UPDATE_PERSONAL_DETAIL_FAILURE'
