import { combineReducers } from 'redux'
import authReducer from './authReducer'
import insuranceReducer from './insuranceReducer'
import analyticsReducer from './analyticsReducer'
import customerReducer from './customerReducer'
import agentReducer from './AgentReducer'
import policyReducer from './policyReducer'
import cmsReducer from './cmsReducer'
import partnerReducer from './partnerReducer'
import { LoaderReducer } from './loaderReducer'
import loanReducer from './loanReducer'
import notificationReducer from './notificationReducer'

const rootReducer = combineReducers({
  Auth: authReducer,
  Insurance: insuranceReducer,
  Analytics: analyticsReducer,
  Customers: customerReducer,
  Agents: agentReducer,
  Policy: policyReducer,
  Cms: cmsReducer,
  Partner: partnerReducer,
  Loader: LoaderReducer,
  Loan: loanReducer,
  Notification: notificationReducer
})

export default rootReducer
