import { GET_ALL_LOAN } from '../actions/types'

const initialState = {
  loans: []
}

const loanReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LOAN:
      return Object.assign({}, state, {
        loans: action.payload
      })
    default:
      return state
  }
}

export default loanReducer
