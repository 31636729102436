import { ADD_POLICY, ADD_POLICY_FAILURE, GET_POLICY } from '../actions/types'

const initialState = {
  policies: [],
  policyError: ''
}

const policyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_POLICY:
      return Object.assign({}, state, {
        policies: action.payload
      })
    case ADD_POLICY:
      return Object.assign({}, state, {
        policyError: ''
      })
    case ADD_POLICY_FAILURE:
      return Object.assign({}, state, {
        policyError: action.payload.error || action.payload.message
      })
    default:
      return state
  }
}

export default policyReducer
