import { pathOr } from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  Router,
  Switch,
  Route
} from 'react-router-dom'
import { AgentRoute } from '../components/ProtectedRoute'
import ActivityIndicator from '../container/ActivityIndicator'
import { history } from '../lib/history'

const AgentDashboard = React.lazy(() => import('../container/Agent'))
const AgentLocation = React.lazy(() => import('../container/Agent/AgentLocation'))
const AgentAddPolicy = React.lazy(() => import('../container/Agent/AddPolicy'))
const MyCustomer = React.lazy(() => import('../container/Agent/Customer'))
const CustomerPolicy = React.lazy(() => import('../container/Agent/CustomerPolicy'))
const Home = React.lazy(() => import('../container/Home'))
const Terms = React.lazy(() => import('../container/Legal/Terms'))
const Privacy = React.lazy(() => import('../container/Legal/Privacy'))

const Navigation = () => {
  const role = useSelector(state => pathOr([], ['role'], state.Auth))
  const isLoading = useSelector(state => pathOr(false, 'isLoading', state.Loader))
  const isLoggedIn = useSelector(state => pathOr([], ['isLoggedIn'], state.Auth))
  const isAgent = !!role.includes('agent')

  return (
    <>
    <ActivityIndicator isLoading={isLoading} />
        <Router history={history}>
            <Switch>
                <Route path="/" render={() => <Home />} exact />
                <Route path="/home" render={() => <Home />} exact />
                <Route path="/terms" render={() => <Terms />} exact />
                <Route path="/privacypolicy" render={() => <Privacy />} exact />
                <AgentRoute isAuthenticated={isLoggedIn} isAgent ={isAgent} path="/agent/dashboard" component={AgentDashboard} />
                <AgentRoute isAuthenticated={isLoggedIn} isAgent ={isAgent} path="/agent/customer/:customerId/add" component={AgentAddPolicy} />
                <AgentRoute isAuthenticated={isLoggedIn} isAgent ={isAgent} path="/agent/customer/:customerId/policy" component={CustomerPolicy} />
                <AgentRoute isAuthenticated={isLoggedIn} isAgent ={isAgent} path="/agent/customer" component={MyCustomer} />
                <AgentRoute isAuthenticated={isLoggedIn} isAgent ={isAgent} path="/agent/location" component={AgentLocation} />
            </Switch>
        </Router>
        </>
  )
}

export default Navigation
