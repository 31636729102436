import { GET_AGENT_ANALYTICS, GET_ALL_AGENT_LOCATION, GET_BRIEZI_COINS, GET_CUSTOMER_ANALYTICS } from '../actions/types'

const initialState = {
  agent: {
    count: {}
  },
  customer: {
    count: {}
  },
  coins: [],
  location: []
}

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AGENT_ANALYTICS:
      return Object.assign({}, state, {
        admin: {
          count: action.payload
        }
      })
    case GET_CUSTOMER_ANALYTICS:
      return Object.assign({}, state, {
        customer: {
          count: action.payload
        }
      })
    case GET_BRIEZI_COINS:
      return Object.assign({}, state, {
        coins: action.payload
      })
    case GET_ALL_AGENT_LOCATION:
      return Object.assign({}, state, {
        location: action.payload
      })
    default:
      return state
  }
}

export default analyticsReducer
