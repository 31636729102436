import { TOGGLE_LOADER } from '../actions/types'

const initialState = {
  isLoading: false
}

export const LoaderReducer = (state = initialState, action) => {
  switch (action) {
    case TOGGLE_LOADER:
      return Object.assign({}, state, {
        isLoading: action.payload
      })
    default:
      return state
  }
}
