import React from 'react'
import Loading from 'react-fullscreen-loading'
const Loader = () => {
  return (
        <div>
           return <Loading loading={true} background="#000000" loaderColor="#ffffff" />;
        </div>
  )
}

export default Loader
