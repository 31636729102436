import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

export const AdminRoute = ({
  component: Component,
  isAuthenticated,
  isAdmin,
  ...rest
}) => {
  return (
        <Route exact {...rest} render={(props) => {
          if (isAuthenticated && isAdmin) {
            return <Component />
          } else {
            return (
                    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            )
          }
        }}
        />
  )
}

export const AgentRoute = ({
  component: Component,
  isAuthenticated,
  isAgent,
  ...rest
}) => {
  return (
        <Route {...rest} render={(props) => {
          if (isAuthenticated && isAgent) {
            return <Component />
          } else {
            return (
                    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            )
          }
        }}
        />
  )
}

export const CustomerRoute = ({
  component: Component,
  isAuthenticated,
  isUser,
  ...rest
}) => {
  return (
        <Route {...rest} render={(props) => {
          if (isAuthenticated && isUser) {
            return <Component />
          } else {
            return (
                    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            )
          }
        }}
        />
  )
}

export const PartnerRoute = ({
  component: Component,
  isAuthenticated,
  isPartner,
  ...rest
}) => {
  return (
        <Route {...rest} render={(props) => {
          if (isAuthenticated && isPartner) {
            return <Component />
          } else {
            return (
                    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            )
          }
        }}
        />
  )
}

AdminRoute.propTypes = {
  component: PropTypes.node,
  isAuthenticated: PropTypes.bool,
  isAdmin: PropTypes.bool,
  location: PropTypes.string
}

AgentRoute.propTypes = {
  component: PropTypes.node,
  isAuthenticated: PropTypes.bool,
  isAgent: PropTypes.bool,
  location: PropTypes.string
}

CustomerRoute.propTypes = {
  component: PropTypes.node,
  isAuthenticated: PropTypes.bool,
  isUser: PropTypes.bool,
  location: PropTypes.string
}

PartnerRoute.propTypes = {
  component: PropTypes.node,
  isAuthenticated: PropTypes.bool,
  isPartner: PropTypes.bool,
  location: PropTypes.string
}
