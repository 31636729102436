import React from 'react'
import Loading from 'react-fullscreen-loading'
import PropTypes from 'prop-types'
const ActivityIndicator = (props) => {
  return (
        <div>
            <Loading loading={props.isLoading} background="#000000" loaderColor="#ffffff" />
        </div>
  )
}

ActivityIndicator.propTypes = {
  isLoading: PropTypes.bool
}

export default ActivityIndicator
