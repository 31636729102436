import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/store'
import Navigation from './routes'
import Loader from './components/Loader'
import ActivityIndicator from './container/ActivityIndicator'
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';
const App = () => {
  const state = store.getState()
  const isLoading = state.Loader.isLoading
  return (
    <Provider store={store}>
      <ActivityIndicator isLoading={isLoading} />
      <PersistGate loading={<Loader />} persistor={persistor}>
        <Suspense fallback={<Loader />}>
          <div className="App">
            <Navigation />
            <NotificationContainer />
          </div>
        </Suspense>
      </PersistGate>
    </Provider>
  )
}

export default App
