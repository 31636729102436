import { AUTH_FAILURE, UPDATE_AUTH_TOKEN, V1_AUTH_SUCCESS, CLEAR_FLAG, FORGOT_PASSWORD_FAILURE, LOGOUT, RESET_PASSWORD_FAILURE, RESET_PASSWORD_SUCCESS, SIGNUP_FAILURE, SIGNUP_SUCCESS, UPDATE_LOCATION, VALIDATE_OTP_FAILURE, VALIDATE_OTP_SUCCESS, UPDATE_PROFILE, UPDATE_PERSONAL_DETAIL_SUCCESS } from '../actions/types'

const initialState = {
  _id: '',
  email: '',
  mobileNumber: '',
  isMobileNumberVerified: '',
  isEmailverified: '',
  firstName: '',
  lastName: '',
  accessToken: '',
  isSignupcomplete: false,
  register: {
    mobileNumber: '',
    email: ''
  },
  authError: '',
  dob: '',
  address: '',
  isOtpVerified: false,
  isLoggedIn: false,
  fcm: null,
  referralCode: ''
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case V1_AUTH_SUCCESS:
      return Object.assign({}, state, {
        authError: ''
      })
    case UPDATE_AUTH_TOKEN: {
      const { accessToken } = action.payload
      return Object.assign({}, state, {
        accessToken
      })
    }
    case SIGNUP_SUCCESS:
      return Object.assign({}, state, {
        regError: '',
        isSignupcomplete: true
      })
    case VALIDATE_OTP_SUCCESS:
      return Object.assign({}, state, {
        otpError: '',
        isOtpVerified: true,
        isLoggedIn: true,
        _id: action.payload._id,
        email: action.payload.email,
        mobileNumber: action.payload.mobileNumber,
        isMobileNumberVerified: action.payload.isEmailverified,
        isEmailverified: action.payload.isEmailverified,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        role: action.payload.role,
        isSignupcomplete: false,
        dob: action.payload.dob,
        location: action.payload.location
      })
    case LOGOUT:
      return Object.assign({}, state, {
        _id: '',
        email: '',
        mobileNumber: '',
        isMobileNumberVerified: '',
        isEmailverified: '',
        firstName: '',
        lastName: '',
        authError: '',
        regError: '',
        otpError: '',
        forgotError: '',
        resetError: '',
        accessToken: '',
        isSignupcomplete: false,
        isOtpVerified: false,
        role: [],
        dob: '',
        isLoggedIn: false,
        location: []
      })
    case RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        isSignupcomplete: false,
        isOtpVerified: false,
        isForgotPasswordRequested: false,
        isPasswordResetCompleted: true
      })
    case CLEAR_FLAG:
      return Object.assign({}, state, {
        resetError: '',
        isForgotPasswordRequested: false,
        isPasswordResetCompleted: false,
        authError: '',
        regError: '',
        otpError: '',
        forgotError: '',
        isSignupcomplete: ''
      })
    case UPDATE_LOCATION:
      return Object.assign({}, state, {
        location: action.payload
      })
    case AUTH_FAILURE:
      return Object.assign({}, state, {
        authError: action.payload.error || action.payload.message
      })
    case SIGNUP_FAILURE:
      return Object.assign({}, state, {
        regError: action.payload.error || action.payload.message,
        isSignupcomplete: false
      })
    case VALIDATE_OTP_FAILURE:
      return Object.assign({}, state, {
        otpError: action.payload.error || action.payload.message,
        isOtpVerified: false
      })
    case FORGOT_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        forgotError: action.payload.error || action.payload.message
      })
    case RESET_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        resetError: action.payload.error || action.payload.message
      })
    case UPDATE_PROFILE: {
      const { firstName, dob, address } = action.payload
      return Object.assign({}, state, {
        firstName,
        dob,
        address
      })
    }
    case UPDATE_PERSONAL_DETAIL_SUCCESS: {
      const { firstName, lastName, email, referredBy } = action.payload
      return Object.assign({}, state, {
        firstName,
        lastName,
        email,
        referredBy
      })
    }
    default:
      return state
  }
}

export default authReducer
