import { EDIT_INSURANCE_FAILURE, GET_INSURANCE_PLANS_FAILURE, EDIT_INSURANCE_SUCCESS, GET_INSURANCE_PLANS, GET_INSURANCE_PROVIDERS } from '../actions/types'

const initialState = {
  provider: [],
  plans: [],
  editError: ''
}

const insuranceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSURANCE_PROVIDERS:
      return Object.assign({}, state, {
        provider: action.payload
      })
    case EDIT_INSURANCE_SUCCESS:
      return Object.assign({}, state, {
        editError: ''
      })
    case EDIT_INSURANCE_FAILURE:
      return Object.assign({}, state, {
        editError: action.payload.error || action.payload.message
      })
    case GET_INSURANCE_PLANS:
      return Object.assign({}, state, {
        plans: action.payload
      })
    case GET_INSURANCE_PLANS_FAILURE:
      return Object.assign({}, state, {
        plans: []
      })
    default:
      return state
  }
}

export default insuranceReducer
